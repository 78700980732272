import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/dist/tailwind'
import { defineConfig } from '@vueform/vueform'
import builder from '@vueform/builder/plugin'
import { messages } from './src/data';


console.log('[defineConfig] apikey: h3cv-b9ag-wwzt-hchl-trkq');

export default defineConfig({
  ai: {
    enabled: true,
    messages: [],
    shuffle: false
  },
  endpoints: {
    uploadTempFile:{
      url: '/api/upload-temp',
      method: 'POST'
    },
    // or
    /* uploadTempFile: async (value, el$) => {
      console.log('[uploadTempFile] value', value);
      console.log('[uploadTempFile] el$', el$);
    } */
  },
  providerOptions: {
    recaptcha2: {
      sitekey: '6LdN8XcqAAAAAOtOcN5ZaMFtVOWeF3ECGRthfGoM'
    }
  },
  theme: tailwind,
  locales: { en },
  locale: 'en',
  //apiKey: 'oxdh-toeb-ojxl-z7x2-0zrc',
  //apiKey: 'zgje-ubma-gzab-0rix-kzen',
  apiKey: 'h3cv-b9ag-wwzt-hchl-trkq',
  plugins: [
    builder,
  ],
})