import axios from "axios";

const getFormStatus = (item) => {
    if (item.isUnpub) {
        return 'Unpublished';
    } else if (!item.hasPub) {
        return 'Draft';
    } else if (item.draft?.data) {
        return 'Draft';
    } else {
        return 'Published';
    }
};
/*const blobToBase64 = (file) => {
    console.log('[blobToBase64] file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
        console.log('[blobToBase64] e', e);
        console.log('[blobToBase64] reader.result', reader.result);
        return reader.result;
    };
};*/
// 获取图片file转base64
const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};


// Base64 转为 Blob
const base64ToBlob = (fileDataURL) => {
    console.log('[base64ToBlob] fileDataURL', fileDataURL);
    let arr = fileDataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    console.log('[base64ToBlob] mime', mime);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

const fileAndBlobToCanvas = (fileDataURL, canvas) => {
    let img = new Image();
    img.src = fileDataURL;
    /*let canvas = document.createElement('canvas')
    if (!canvas.getContext) {
      alert('浏览器不支持canvas')
      return
    }*/
    let ctx = canvas.getContext('2d');
    //document.getElementById('container').appendChild(canvas);
    img.onload = () => {
        ctx.drawImage(img, 0, 0, img.width, img.height)
    }
}

const formatViewData = (data) => {

    console.log('[formatViewData] data', data);

    const keys = Object.keys(data);
    if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            console.log('[formatViewData] key', key);
            if (key.indexOf('signature') === 0) {

                console.log('[formatViewData] val', data[key]);

                const blob = base64ToBlob(data[key]);

                console.log('[formatViewData] ', key, blob);

                data[key] = blob;
            }
        }
    }

    return data;
};

const formatFormData = (data) => {


    return new Promise((resolve, _) => {



        console.log('[formatFormData] data', data);

        let signatureKey = '';
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key.indexOf('signature') === 0) {
                signatureKey = key;
                break;
            }
        }
        if (signatureKey && signatureKey.length > 0) {
            blobToBase64(data[signatureKey]).then((base64) => {

                console.log('[formatFormData] ', signatureKey, base64);
                data[signatureKey] = base64;

                resolve(data);
            });
        } else {

            resolve(data);
        }
    });

};


const getUploadFiles = (data, formId) => {

    let files = [];

    const keys = Object.keys(data);
    console.log('[getUploadFiles] keys', keys);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key.indexOf('image') === 0 || key.indexOf('file') === 0) {
            files.push({
                key: key,
                formId: formId,
                url: '',
                data: data[key]
            });
        }
    }

    return files;
};



const uploadFiles = (files) => {
    console.log('[uploadFiles] files', files);
    const promises = files.map((file, index) => {
        const formData = new FormData();
        formData.append('file', file.data);
        formData.append('formId', file.formId);

        return axios.post('/api/upload-temp', formData)
            .then(response => response.json())
            .then(data => {
                console.log('File uploaded:', data);
                //files[index]
            })
            .catch(error => console.error('Error uploading file:', error));
    });

    return Promise.all(promises); // 返回一个Promise，所有文件上传完毕后解决
};

export { getFormStatus, blobToBase64, base64ToBlob, fileAndBlobToCanvas, formatViewData, formatFormData, uploadFiles, getUploadFiles };