<template>
    <div class="h-screen">
        <div v-if="siteInfo.ErrorMsg.length > 0" id="ci_error_msg" class="hwifMk">
            <div class="msg">{{ siteInfo.ErrorMsg }}</div>
            <div class="btn" @click="deleteError">X</div>
        </div>
        <main ref="formContainer" id="view-form-main">
            <Vueform ref="form" :disabled="true" :schema="formData" :endpoint="getEndpoint" />
        </main>
    </div>
</template>
<script>
import axios from 'axios';
import { emptyData } from '../data';
import { apiConfig } from '../config/api';
import { getFormStatus, formatViewData } from '../utilities/dataHelper';

export default {
    name: 'Builder',
    data() {
        return {
            formData: emptyData.schema,
            siteInfo: {
                ErrorMsg: '',
                EnableSave: false
            },
            formInfo: {
                Title: 'My Form',
                //Id: parseInt(this.$route.params['formId'] || 0),
                Uid: this.$route.params['formId'],
                Id: 0,
                SiteId: 5,
                FolderId: 0,
                Data: '',
                HasPub: false,
                HasDraft: false
            },
            state: {
                resizeObserve: null
            }
        }
    },
    methods: {
        deleteError() {
            this.siteInfo.ErrorMsg = '';
        },
        getFormSchema() {
            axios({
                method: 'get',
                url: `${apiConfig.GetApiUrl}/${this.formInfo.Uid}`,
            }).then((res) => {

                let result = res.data;


                const status = getFormStatus(result);
                const title = result.title;
                let _fromData = (result.data || "");
                if (_fromData.length < 100) {
                    _fromData = JSON.stringify(emptyData);
                }
                this.formInfo = {
                    Id: parseInt(result.id),
                    SiteId: parseInt(result.siteId || 5),
                    FolderId: parseInt(result.folderId),
                    Title: title,
                    Uid: this.$route.params["formId"],
                    Data: _fromData,
                    Status: status
                };


                let schema = JSON.parse(_fromData).schema;

                const keys = Object.keys(schema);

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (key.indexOf('captcha') === 0) {
                        console.log('[getFormSchema] remove', key);
                        delete schema[key];
                    }
                }
                console.log('[getFormSchema] schema', schema);

                this.formData = schema;

                this.getFormSubmission();


            }).catch(e => {
                console.error('[get form schema] error:', e);
                this.siteInfo.ErrorMsg = `getting form schema error: ${e.message}`;
                this.formData = emptyData.schema;
            });

        },
        getFormSubmission() {
            axios({
                method: 'get',
                url: `${apiConfig.GetSubmissionUrl}/${this.formInfo.Uid}`,
            }).then((res) => {
                const data = this.findSubmission(res.data);



                console.log('[getFormSubmission] data', data);
                console.log('[getFormSubmission] this.$refs.form', this.$refs.form);
                this.$refs.form.update(data);

            }).catch((err) => {
                console.error('[get form submission] error:', err);
                this.siteInfo.ErrorMsg = `getting form submission error: ${err.message}`;
            });
        },
        findSubmission(data) {
            try {
                let id = parseInt(this.$route.params['itemId']);
                let pageId = parseInt(this.$route.params['pageId']);
                if (data && data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        let item = data[i];
                        if (item.id === id && item.pageId == pageId) {
                            let res = JSON.parse(item.data);

                            return formatViewData(res);
                        }
                    }
                }
                return null;

            } catch (e) {
                console.log('[find submission] error:', e);
                this.siteInfo.ErrorMsg = `submission not found, id: ${this.$route.params['itemId']}`;
                return null;
            }
        },
        loadEmptyData() {
        },
    },
    created() {

    },
    async mounted() {

        this.$nextTick(() => {

            if (!this.state.resizeObserve) {
                this.state.resizeObserve = new ResizeObserver((entires) => {
                    for (const entry of entires) {
                        if (entry.target === this.$refs.formContainer) {
                            let formHeight = this.$refs.formContainer?.offsetHeight;
                            if (formHeight) {
                                let formId = this.formInfo.Id;
                                let cbMsg = {
                                    type: 'Form.Resize',
                                    id: formId,
                                    val: formHeight + 10
                                };
                                console.log('[postMessage]', cbMsg);
                                parent.postMessage(cbMsg, "*")

                            }
                        }
                    }
                });
            }
            this.state.resizeObserve.observe(this.$refs.formContainer);
        });
        this.getFormSchema();

    },
}
</script>