<template>
  <div class="h-screen wrapper">
    <div v-if="dialogInfo.show" class="ci-layer">
      <Dialog :info="dialogInfo" :handleOk="handleDialogOk" :handleCancel="handleDialogCancel"></Dialog>
    </div>
    <div v-if="alertDialogInfo.show" class="ci-layer">
      <Dialog :info="alertDialogInfo" :handleOk="handleAlertDialogOk" :handleCancel="handleAlertDialogCancel"></Dialog>
    </div>
    <Header :info="formInfo" :siteInfo="siteInfo" :submit="setFormData" :submitDraft="saveAsDraft"
      :deleteDraft="deleteDraft" :submitUnpublish="handleUnpublish" :submitPublish="publish" :login="handleLogin"
      :cancel="handleCancel" :changeMode="handleChangeMode" :state="msalState"></Header>
    <div v-if="siteInfo.ErrorMsg.length > 0" id="ci_error_msg" class="hwifMk">
      <div class="msg">{{ siteInfo.ErrorMsg }}</div>
      <div class="btn" @click="deleteError">X</div>
    </div>
    <main>
      <VueformBuilder :config="vfConfig" ref="ciFormBuilder" @save="handleSave" />
    </main>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Dialog from "../components/Dialog.vue";
import axios from "axios";
import { emptyData, messages } from "../data";
import { useAuth } from "../auth/useAuth";
import { apiConfig } from "../config/api";
import { useConfig, isDevMode } from "../config/builderConfig";
import { getFormStatus } from '../utilities/dataHelper';


const { msalState, msalApp, postRequest, retryLogin } = useAuth();

export default {
  name: "Builder",
  data() {
    return {
      vfConfig: useConfig(this.$route.query),
      msalState: msalState,
      siteInfo: {
        IsDeveloperMode: isDevMode(this.$route.query),
        SgUrl: apiConfig.sgUrl,
        ErrorMsg: "",
        EnableSave: false,
      },
      formInfo: {
        Title: "My Form",
        Id: 0,
        //Id: parseInt(this.$route.params['formId'] || 0),
        Uid: this.$route.params["formId"],
        SiteId: 5,
        FolderId: 0,
        Data: "",
        Status: "Published",
        HasPub: false,
        HasDraft: false
      },
      dialogInfo: {
        show: false,
        header: "Save",
        loading: "processing...",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: false
      },
      alertDialogInfo: {
        show: false,
        header: "Alert",
        loading: "processing...",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: true
      },
      theme: {
        primary: '#0078d4',
        darker: '#0078d4'
      },
      pubData: ''
    };
  },
  components: {
    Header,
    Dialog,
  },
  methods: {
    deleteError() {
      this.siteInfo.ErrorMsg = "";
    },
    handleDialogOk() {
      this.dialogInfo = {
        show: false,
        header: "Save",
        loading: "",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: false
      };
    },
    handleDialogCancel() {
      this.dialogInfo = {
        show: false,
        header: "Save",
        loading: "",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: false
      };
    },
    handleAlertDialogOk() {
      this.alertDialogInfo = {
        show: false,
        header: "Alert",
        loading: "",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: true
      };
      
      if (this.msalState.isAuthenticated) {

        let dto = {
          SiteId: this.formInfo.SiteId,
          Id: this.formInfo.Id,
          IsDraft: false,
          Draft: null
        };

        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Upsert", args: JSON.stringify(dto) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;

            if (this.formInfo.Status !== "Unpublished") {
              this.formInfo.Status = "Published";
              this.formInfo.HasDraft = false;
            }


            let _fromData = (this.pubData || "");
            if (_fromData.length < 100) {
              _fromData = JSON.stringify(emptyData);
            }
            this.formInfo.Data = _fromData;


            let builderObject = JSON.parse(this.formInfo.Data);
            builderObject.schema = builderObject.schema || {};
            let history = [];
            this.$refs.ciFormBuilder.load(builderObject, history);


            this.unpublish();
            
          } else {

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.FAILED_TO_DELETE_DRAFT, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.FAILED_TO_DELETE_DRAFT, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, header: 'Alert', loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }

    },
    handleAlertDialogCancel() {
      this.alertDialogInfo = {
        show: false,
        header: "Alert",
        loading: "",
        success: "",
        failed: "",
        enabledOK: true,
        enabledCancel: true
      };
    },
    handleChangeMode() {
      let path = `/builder/edit/${this.formInfo.Uid}?v=${this.siteInfo.IsDeveloperMode ? 'simple' : 'advanced'}`;
      window.location.href = path;
    },
    handleCancel() {
      window.close();
    },
    handleLogin() {
      try {
        msalApp
          .loginPopup(postRequest)
          .then((res) => {
            this.msalState.isAuthenticated = true;
            this.msalState.token = res.accessToken;
            this.msalState.user = res.account;

            this.getFormData();
          })
          .catch((e) => {
            console.error("loginPopup error", e);
          });
      } catch (e) {
        console.error("loginPopup error", e);
      }
    },
    handleSave(builderObject, history) {
      let data = JSON.stringify(builderObject);
      this.formInfo.Data = data;
      this.siteInfo.EnableSave = true;
    },
    setFormData() {

      if (this.msalState.isAuthenticated) {

        this.dialogInfo = { ...this.dialogInfo, loading: 'processing...', success: '', failed: '', enabledOK: false, show: true };
        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Upsert", args: JSON.stringify(this.formInfo) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;
            this.dialogInfo = { ...this.dialogInfo, loading: '', success: messages.SAVED_SUCCESSFULLY, enabledOK: true };
          } else {

            this.dialogInfo = { ...this.dialogInfo, loading: '', failed: messages.SAVE_FAILED, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, loading: '', failed: messages.SAVE_FAILED, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }

    },
    saveAsDraft() {

      if (this.msalState.isAuthenticated) {

        this.dialogInfo = { ...this.dialogInfo, header: 'Save as Draft', loading: 'processing...', success: '', failed: '', enabledOK: false, show: true };
        let draftDto = {
          SiteId: this.formInfo.SiteId,
          Id: this.formInfo.Id,
          IsDraft: true,
          Draft: {
            Title: this.formInfo.Title,
            Data: this.formInfo.Data
          }
        };
        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Upsert", args: JSON.stringify(draftDto) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;
            if (this.formInfo.Status !== "Unpublished") {
              this.formInfo.Status = "Draft";
              this.formInfo.HasDraft = true;
            }
            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', success: messages.SAVED_SUCCESSFULLY, enabledOK: true };
          } else {

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.SAVE_FAILED, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.SAVE_FAILED, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, header: 'Alert', loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }

    },
    publish() {

      if (this.msalState.isAuthenticated) {

        this.dialogInfo = { ...this.dialogInfo, header: 'Publish', loading: 'processing...', success: '', failed: '', enabledOK: false, show: true };

        let dto = { ...this.formInfo, IsDraft: false };

        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Upsert", args: JSON.stringify(dto) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;
            this.formInfo.Status = "Published";
            this.formInfo.HasDraft = false;
            this.formInfo.HasPub = true;
            this.pubData = this.formInfo.Data;
            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', success: messages.PUBLISHED_SUCCESSFULLY, enabledOK: true };
          } else {

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.PUBLISH_FAILED, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.PUBLISH_FAILED, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, header: 'Alert', loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }

    },
    handleUnpublish(){
      if(this.formInfo.HasDraft){
        this.alertDialogInfo = { ...this.alertDialogInfo, header: 'Alert', loading: '', failed: 'Unpublishing this form will delete the draft version. Continue?', enabledOK: true, show: true };

      }else{
        this.unpublish();
      }
    },
    unpublish() {
      if (this.msalState.isAuthenticated) {

        this.dialogInfo = { ...this.dialogInfo, header: 'Unpublish', loading: 'processing...', success: '', failed: '', enabledOK: false, show: true };

        let dto = { Id: this.formInfo.Id };

        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Unpublish", args: JSON.stringify(dto) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;
            this.formInfo.Status = "Unpublished";
            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', success: messages.UNPUBLISHED_SUCCESSFULLY, enabledOK: true };
          } else {

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.UNPUBLISH_FAILED, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.UNPUBLISH_FAILED, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, header: 'Alert', loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }
    },
    deleteDraft() {

      if (this.msalState.isAuthenticated) {

        this.dialogInfo = { ...this.dialogInfo, header: 'Delete Draft', loading: 'processing...', success: '', failed: '', enabledOK: false, show: true };

        let dto = {
          SiteId: this.formInfo.SiteId,
          Id: this.formInfo.Id,
          IsDraft: false,
          Draft: null
        };

        axios({
          method: "post",
          url: apiConfig.PostApiUrl,
          data: { path: "Form/Upsert", args: JSON.stringify(dto) },
          headers: {
            Authorization: `Bearer ${this.msalState.token}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            this.siteInfo.EnableSave = false;
            //this.formInfo.Status = "Unpublished";

            if (this.formInfo.Status !== "Unpublished") {
              this.formInfo.Status = "Published";
              this.formInfo.HasDraft = false;
            }


            let _fromData = (this.pubData || "");
            if (_fromData.length < 100) {
              _fromData = JSON.stringify(emptyData);
            }
            this.formInfo.Data = _fromData;

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', success: messages.DRAFT_DELETED_SUCCESSFULLY, enabledOK: true };



            let builderObject = JSON.parse(this.formInfo.Data);
            builderObject.schema = builderObject.schema || {};
            let history = [];
            this.$refs.ciFormBuilder.load(builderObject, history);


          } else {

            this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.FAILED_TO_DELETE_DRAFT, enabledOK: true };
          }
        }).catch((e) => {
          console.error("[setFormData] e", e);

          this.dialogInfo = { ...this.dialogInfo, header: 'Result', loading: '', failed: messages.FAILED_TO_DELETE_DRAFT, enabledOK: true };
        });
      } else {
        this.dialogInfo = { ...this.dialogInfo, header: 'Alert', loading: '', failed: messages.LOGIN, enabledOK: true, show: true };

      }

    },
    getFormData() {
      axios({
        method: "get",
        url: `${apiConfig.GetApiUrl}/${this.formInfo.Uid}`,
      })
        .then((res) => {

          let result = res.data;
          const status = getFormStatus(result);
          const title = result.title;
          let _fromData = ((result.draft?.data || result.data) || "");
          if (_fromData.length < 100) {
            _fromData = JSON.stringify(emptyData);
          }

          this.pubData = result.data || "";
          this.formInfo = {
            Id: parseInt(result.id),
            SiteId: parseInt(result.siteId || 5),
            FolderId: parseInt(result.folderId),
            Title: title,
            Uid: this.$route.params["formId"],
            Data: _fromData,
            Status: status,
            HasPub: result.hasPub,
            HasDraft: !!result.draft?.data
          };


          if (status === 'Unpublished') {
            this.siteInfo.EnableSave = false;
          }


          let builderObject = JSON.parse(this.formInfo.Data);
          builderObject.schema = builderObject.schema || {};
          let history = [];
          this.$refs.ciFormBuilder.load(builderObject, history);
        })
        .catch((e) => {
          console.error('[getFormData] error:', e.message);
          this.siteInfo.ErrorMsg = e.message;
          this.loadEmptyData();
        });
    },
    loadEmptyData() {
      let builderObject = emptyData;
      let history = [];
      this.$refs.ciFormBuilder.load(builderObject, history);
    },
  },
  async mounted() {
    this.getFormData();
  },
};
</script>
<style lang="scss">
.vfb-builder {

  *,
  &:before,
  &:after,
  &:root {
    --vf-primary: v-bind(theme.primary);
    --vf-ring-color: v-bind(theme.darker);
  }
}
</style>