import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Form from '../pages/Form.vue';
import Builder from '../pages/Builder.vue';
import Success from '../pages/Success.vue';
import Failed from '../pages/Failed.vue';
import Error from '../pages/Error.vue';
import View from '../pages/View.vue';

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/',
            component: Error
        },
        {
            path: '/builder',
            component: Error
        },
        {
            path: '/builder/form',
            component: Error
        },
        {
            path: '/builder/',
            component: Error
        },
        {
            path: '/builder/success',
            component: Success
        },
        {
            path: '/builder/failed',
            component: Failed
        },
        {
            path: '/builder/:formId',
            component: Form
        },
        {
            path: '/builder/form/:formId/:email',
            component: Form
        },
        {
            path: '/builder/form/:formId',
            component: Form
        },
        {
            path: '/builder/view/:formId/:pageId/:itemId',
            component: View
        },
        {
            path: '/builder/edit/:formId/:email',
            component: Builder
        },
        {
            path: '/builder/edit/:formId',
            component: Builder
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/'
        }
    ]
});

export default router;