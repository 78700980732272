<script setup>
    import { useRouter, useRoute } from 'vue-router'
    const router = useRouter();
    const route = useRoute();


</script>
<template>
    <h1>Error Form route: {{ route }}</h1>
    <h1>Error Form router: {{ router.currentRoute }}</h1>
</template>
<style scoped>
</style>